/* eslint-disable react/prop-types */
import { extractColors } from '@utils/utils';
import ThreeImageGrid from './ThreeImageGrid';
import React from 'react';

/**
 * Maps contentful content into props for the ThreeImageGrid component.
 *
 * @param {{
 *   mainContent: {
 *     contentType: string,
 *     gridLayout: string,
 *     gridBackgroundColor: string,
 *     images: {
 *       altText: string,
 *       imageAsset: {
 *         file: { url: string }
 *       },
 *     }[]
 *   }[],
 *   index: number,
 * }}
 */
export default function ThreeImageGridContainer({ mainContent, index }) {
  const i = index;
  const contentItem = mainContent[i];

  const props = {
    layoutType: contentItem.gridLayout.toLowerCase().includes('small-large')
      ? 'small-to-large'
      : 'large-to-small',
    images: contentItem.images.map((image) => ({
      src: image.imageAsset.file.url,
      altText: image.altText,
    })),
    backgroundColor: extractColors(contentItem.gridBackgroundColor),
    hasPreviousImageGrid: mainContent[i - 1]?.contentType === 'imageGrid',
    hasNextImageGrid: mainContent[i + 1]?.contentType === 'imageGrid',
  };

  return <ThreeImageGrid {...props} />;
}
