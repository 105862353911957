import React from 'react';
import PropTypes from 'prop-types';
import Markdown from '../Markdown/Markdown';
import ImageCardStyles from './ImageCard.styled';
import { formatHttpsUrl, extractColors, convertHexToRgbA } from '@utils/utils';

const ImageCard = ({ card, style, imageGridReference }) => {
  let markdownText = card?.imageLabel;
  if (markdownText) {
    markdownText = markdownText.replace('.png', '.png?h=70&q=100');
    markdownText = markdownText.replace('.jpg', '.jpg?h=70&q=100');
    markdownText = markdownText.replace('.svg', '.svg?q=100');
  }

  return (
    <ImageCardStyles
      $backgroundImage={formatHttpsUrl(card.imageAsset?.file?.url)}
    >
      {card && (
        <div
          style={{
            background: convertHexToRgbA(
              extractColors(card.overlayColor)[0],
              card.overlayOpacity * 0.01
            ),
          }}
          className={style + ' ' + imageGridReference}
        >
          <div className="footer">
            <Markdown markdown={markdownText} />
          </div>
        </div>
      )}
    </ImageCardStyles>
  );
};

export default ImageCard;

ImageCard.propTypes = {
  card: PropTypes.object,
  blendColor: PropTypes.number,
  style: PropTypes.string,
  imageGridReference: PropTypes.string,
};
