import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import Markdown from '@components/Shared/Markdown/Markdown';
import TextSectionInsetStyles from './TextSectionInset.styled';
import StArrowRightSvg from 'pubweb-smokey/dist/images/svg/iconography-16x16/st-arrow-right.svg';
import {
  extractColors,
  formatHttpsUrl,
  auditMarkdownLinks,
} from '@utils/utils';
import {
  getContentTextAlignment,
  getContentFlexAlignment,
} from '@utils/contentful';
import { slugify } from '@utils/studioUtils';

const TextSectionInset = ({
  alignText,
  sectionLabel,
  labelTextColor,
  sectionHeader,
  headerTextColor,
  featuredImage,
  sectionContent,
  sectionContentTextColor,
  sectionLinks,
  statistics,
  statisticsTextColor,
  sectionBackgroundColor,
}) => {
  useLayoutEffect(() => {
    auditMarkdownLinks('card-desc');
  }, []);

  return (
    <TextSectionInsetStyles
      style={{
        backgroundColor: extractColors(sectionBackgroundColor),
      }}
    >
      <div
        className="full-width-card"
        style={{
          backgroundImage: `url(${formatHttpsUrl(featuredImage?.file?.url)})`,
        }}
      >
        <div className="card-overlay">
          <div className="card-detail">
            <div
              className="card-desc"
              style={{
                textAlign: getContentTextAlignment(alignText),
                color: extractColors(sectionContentTextColor),
              }}
            >
              {sectionLabel && (
                <h5
                  style={{
                    color: extractColors(labelTextColor),
                  }}
                >
                  {sectionLabel}
                </h5>
              )}
              <h3
                style={{
                  color: extractColors(headerTextColor),
                }}
              >
                {sectionHeader}
              </h3>
              <Markdown markdown={sectionContent} />
            </div>
            <ul
              className="card-links"
              style={{
                justifyContent: getContentFlexAlignment(alignText),
              }}
            >
              {sectionLinks?.map((sl, i) => {
                return (
                  <li
                    key={`card-${slugify(sl.linkText)}-${i + 1}`}
                    className="card-link"
                    style={{
                      color: extractColors(sl?.buttonAndtextColors)?.[1],
                      fill: extractColors(sl?.buttonAndtextColors)?.[1],
                    }}
                  >
                    <Link href={sl.link}>
                      <div className="card-link-text">
                        {sl.linkText}{' '}
                        <div className="card-icon">
                          <StArrowRightSvg />
                        </div>
                      </div>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
          {statistics && (
            <div
              className="card-stats"
              style={
                statisticsTextColor && {
                  color: extractColors(statisticsTextColor),
                }
              }
            >
              <Markdown markdown={statistics} />
            </div>
          )}
        </div>
      </div>
    </TextSectionInsetStyles>
  );
};

export default TextSectionInset;

TextSectionInset.propTypes = {
  alignText: PropTypes.string,
  sectionLabel: PropTypes.string,
  labelTextColor: PropTypes.string,
  sectionHeader: PropTypes.string,
  headerTextColor: PropTypes.string,
  sectionSubHeader: PropTypes.string,
  featuredImage: PropTypes.shape({
    description: PropTypes.string,
    file: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  imageLocation: PropTypes.string,
  imageSetting: PropTypes.string,
  sectionContent: PropTypes.string,
  sectionContentTextColor: PropTypes.string,
  sectionLinks: PropTypes.arrayOf(
    PropTypes.shape({
      linkText: PropTypes.string,
      link: PropTypes.string,
      buttonAndtextColors: PropTypes.string,
    })
  ),
  sectionLinkText: PropTypes.string,
  sectionLink: PropTypes.string,
  statistics: PropTypes.string,
  statisticsTextColor: PropTypes.string,
  insetBlock: PropTypes.string,
  disclaimer: PropTypes.string,
  sectionBackgroundColor: PropTypes.string,
};
