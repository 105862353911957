import styled from 'styled-components';
import {
  desktop_breakpoint,
  desktop_container_maxwidth_compact,
} from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
import Colors from 'pubweb-smokey/dist/colors';

export const CTAStyles = styled.div`
  background-color: ${(props) =>
    props.$backgroundColor ? props.$backgroundColor : '#fff'};
  background-image: url(${(props) =>
    props.$backgroundImage ? props.$backgroundImage : 'none'});
  background-position-x: ${(props) =>
    props.$backgroundImageAlign ? props.$backgroundImageAlign : 'right'};
  background-position-y: ${(props) =>
    props.$backgroundImageAlign === 'center' ? 'top' : 'center'};
  background-repeat: no-repeat;
  background-size: ${(props) =>
    props.$backgroundImageAlign === 'center' || props.$isPreFooterCTA
      ? 'cover'
      : 'contain'};
  padding: 20px;

  &.linked-card {
    cursor: pointer;
  }

  .cta-heading,
  .cta-text,
  .custom-category {
    text-align: ${(props) =>
      props.$contentAlignment ? props.$contentAlignment : 'left'};
  }
  .cta-heading,
  .cta-text,
  .label {
    color: ${(props) => (props.$textColor ? props.$textColor : '#fff')};
    margin: 15px auto;
  }
  .cta-heading-prefooter {
    font-size: 36px;
  }
  .label {
    margin-bottom: 5px;
  }
  .cta-heading {
    margin-top: 0;
  }
  .cta-buttons {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: ${(props) =>
      props.$buttonAlignment ? props.$buttonAlignment : 'flex-start'};

    > a {
      margin-right: 20px;
    }
    .cta-button-outline {
      border-color: ${(props) =>
        props.$borderColor
          ? props.$borderColor
          : Colors.primary.white.standard};
    }
  }

  @media only screen and (min-width: ${desktop_breakpoint}px) {
    .cta-buttons {
      flex-direction: row;
      justify-content: ${(props) =>
        props.$buttonAlignment ? props.$buttonAlignment : 'flex-start'};
    }
  }

  @media only screen and (min-width: ${desktop_breakpoint}px) {
    border-radius: ${(props) => (props.$isPreFooterCTA ? '0' : '4px')};
    margin: 0 auto;
    max-width: ${(props) => (props.$isPreFooterCTA ? 'none' : '910px')};
    text-align: left;

    .cta-heading,
    .cta-text {
      max-width: ${(props) =>
        props.$contentAlignment === 'left' ||
        props.$contentAlignment === 'right'
          ? '65%'
          : null};
      margin-left: ${(props) =>
        props.$contentAlignment === 'right' ? 'auto' : 0};
      margin-right: 0;
    }
    .cta-heading-prefooter,
    .cta-text-prefooter {
      max-width: 100%;
    }
    .cta-heading-prefooter {
      font-size: 40px;
    }
    .cta-inside {
      max-width: ${(props) =>
        props.$isPreFooterCTA
          ? desktop_container_maxwidth_compact + 'px'
          : 'none'};
      padding: 30px;
      text-align: left;
      margin: 0 auto;
      height: 100%;
      width: 100%;
    }
    .cta-buttons {
      margin-left: 0;
    }
  }
`;
