import React from 'react';
import PropTypes from 'prop-types';
import CTASectionStyles from './CTASection.styled';
import { extractColors } from '@utils/utils';
import CTA from '../CTA/CTA';
import { getCtaProperties } from '@utils/ctaUtils';
import { slugify } from '@utils/studioUtils';

const CTASection = ({ section }) => {
  return (
    <CTASectionStyles
      bgColor={extractColors(section?.ctaBackgroundColor)}
      id={slugify(section?.ctaHeader)}
    >
      <CTA
        {...getCtaProperties(section)}
        button2GtmClass="gtm-crsmd-freddie-mac"
        buttonGtmClass="gtm-crsmd-fannie-mae"
        buttonAutomationId="link-fannie-mae"
        button2AutomationId="link-freddie-mac"
        ctaSize={section.ctaSize}
      />
    </CTASectionStyles>
  );
};

export default CTASection;

CTASection.propTypes = {
  section: PropTypes.object,
};
