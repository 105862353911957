import React from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'react-responsive-carousel';
import { SlideshowVendorStyles } from '@components/Shared/Slideshow/SlideshowVendorStyles.styled';
import { SlideshowStyles } from './Slideshow.styled';
import { useHorizontalSwipe } from '@utils/hooks';

const Slideshow = ({
  imageArray,
  isDesktop,
  viewportWidth,
  transitionTime = 300,
  ...props
}) => {
  const showArrows = imageArray && imageArray.length > 1;

  // On mobile (touch device), the carousel sometimes moves up/down vertically when swiping.
  // Added this code to detect horizontal swipe and prevent the up/down scrolling.
  useHorizontalSwipe('slideshow-carousel');

  return (
    <SlideshowVendorStyles className="slideshow">
      <SlideshowStyles $showArrows={showArrows} $viewportWidth={viewportWidth}>
        <div id="slideshow-carousel">
          {!!imageArray.length && (
            <Carousel
              showArrows={showArrows}
              showIndicators={isDesktop && true}
              showStatus={false}
              transitionTime={transitionTime}
              thumbWidth={130}
              infiniteLoop={true}
              preventMovementUntilSwipeScrollTolerance={true}
              swipeable={true}
              swipeScrollTolerance={30}
              {...props}
            >
              {imageArray.map((image, i) => {
                if (image.reference) {
                  return (
                    <div key={i}>
                      <img
                        src={
                          image.reference +
                          (viewportWidth ? '?width=' + viewportWidth : '')
                        }
                        alt={
                          image.roomDescription
                            ? image.roomDescription
                            : 'Detail Image'
                        }
                      />
                    </div>
                  );
                } else {
                  return image;
                }
              })}
            </Carousel>
          )}
        </div>
      </SlideshowStyles>
    </SlideshowVendorStyles>
  );
};

export default Slideshow;

Slideshow.propTypes = {
  imageArray: PropTypes.array,
  virtualTourReference: PropTypes.string,
  isDesktop: PropTypes.bool,
  viewportWidth: PropTypes.number,
  transitionTime: PropTypes.number,
};
