import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getModelsByModelNumbers } from '@services/homesService';
import FeaturedHomeStyles from './FeaturedHomes.styled';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

import HomesCarousel from '../../Home/FeaturedHomes/HomesCarousel/HomesCarousel';

import LoadingIndicator from '@components/Shared/LoadingIndicator/LoadingIndicator';
import { getBaseAwsApiUrl } from '@utils/config';
import LinkWrapper from '../../LinkWrapper/LinkWrapper';
import { slugify } from '@utils/studioUtils';

const FeaturedHomes = ({ section, headlineLinkUrl, headlineLinkClassName }) => {
  const [featuredHomes, setFeaturedHomes] = useState([]);
  const [updating, setUpdating] = useState(false);
  async function getModels(modelNumbers) {
    let models = [];
    setUpdating(true);
    await getModelsByModelNumbers(modelNumbers, null, null, null).then(
      (results) => {
        models.push(...results);
      }
    );

    // The home models returned by the query did not have a complete URL for the images.
    // I looked at the image URL for a home returned by getModelByModelNumber and noticed
    // it was prefixed with the AWS API URL plus "images/". So I go in and prefix the image
    // URLs with that information to make them work.
    let realModels = [];
    const imgBaseUrl = getBaseAwsApiUrl() + 'images/';
    realModels = models.map((m) => {
      for (let i = 0; i < m.images.length; i++) {
        // does not start with http or https
        if (m.images[i].reference.search(/http/i) === -1) {
          m.images[i].reference = imgBaseUrl.concat(m.images[i].reference);
        }
      }

      // Look for an exterior view in the images. If found, make it the first image displayed.
      const extImg = m.images.find((e) => e.imageType === 1);
      if (extImg) {
        const idx = m.images.indexOf(extImg);
        //  idx is -1 if none found and 0 is already the first image.
        if (idx > 0) {
          m.images.splice(idx, 1);
          m.images.splice(0, 0, extImg);
        }
      }

      return m;
    });

    // Save the models and indicate we are finished with the update.
    setFeaturedHomes(realModels);
    setUpdating(false);
  }

  useEffect(() => {
    if (section?.modelNumberList) {
      // Note: the flatmap and split regex for spaces because the list in Contentful had two model numbers that were not comma separated.
      // If it happend once it could happen again.
      const modelNumbers = section?.modelNumberList
        .trim()
        .split(',')
        .flatMap((m) => {
          return m.trim().split(/\s+/);
        });
      getModels(modelNumbers);
    }
  }, [section]);

  const getSectionTitle = ({ txt }) => {
    return <p className="h3 headline">{txt}</p>;
  };

  const getSectionHeader = ({ txt, url, className }) => {
    return !url || url === '' ? (
      getSectionTitle({ txt })
    ) : (
      <LinkWrapper className={`headline-link ${className}`} url={url}>
        {getSectionTitle({ txt })}
      </LinkWrapper>
    );
  };

  return (
    <FeaturedHomeStyles id={slugify(section?.sectionHeader)}>
      {getSectionHeader({
        txt: section?.sectionHeader || '',
        url: headlineLinkUrl,
        className: headlineLinkClassName,
      })}
      {updating && (
        <div className="loading-indicator">
          <LoadingIndicator width="64" height="64" />
        </div>
      )}
      {!updating && (
        <>
          {featuredHomes?.length > 0 && (
            <HomesCarousel
              homes={featuredHomes}
              ctas={section.featuredCTAs}
              isHomePage={true}
            />
          )}
        </>
      )}
    </FeaturedHomeStyles>
  );
};

export default FeaturedHomes;

FeaturedHomes.propTypes = {
  modelNumberList: PropTypes.string,
  section: PropTypes.object,
  headlineLinkUrl: PropTypes.string,
  headlineLinkClassName: PropTypes.string,
};
