import styled from 'styled-components';
import {
  desktop_breakpoint_extended,
  sm_laptop_breakpoint,
  sm_desktop_breakpoint,
  sm_mobile_breakpoint,
  lg_mobile_breakpoint,
  tablet_portrait_breakpoint,
} from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
import Colors from 'pubweb-smokey/dist/colors.js';

export const VideoSectionStyles = styled.div`
  .video-container {
    margin: 0 auto;
    padding: 2rem 1rem;
    position: relative;

    h2,
    h3 {
      color: ${Colors.primary.black.standard};
      margin-bottom: 1rem;
    }

    h3 {
      font-size: 26px;
      line-height: 31px;
    }
  }

  .slide-card {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding: 3rem 2rem;

    .slide-image {
      margin-bottom: 4rem;
      width: 100%;

      img {
        height: auto;
      }
    }

    .slide-detail {
      text-align: left;

      p {
        color: inherit;
        margin-bottom: 1rem;
      }
    }

    &.video-layout-left {
      .slide-image {
        margin-bottom: 1rem;
        order: 0;
      }

      .slide-detail {
        order: 1;
      }
    }

    &.video-layout-right {
      .slide-image {
        order: 1;
      }

      .slide-detail {
        margin-bottom: 1rem;
        order: 0;
      }
    }

    .slide-links {
      list-style: none;
      display: flex;
      flex-direction: column;
    }

    .slide-link {
      margin-bottom: 0.5em;
    }

    .slide-link a {
      display: flex;
      flex-direction: row;
      justify-content: left;
      font-family: 'acumin-pro', sans-serif;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: -0.3px;
      text-decoration: none;

      .slide-icon {
        display: inline-block;
        height: 14px;
        position: relative;
        width: 16px;
        transition: 0.5s;

        svg {
          margin-left: 5px;
          position: absolute;
          top: 2px;

          * {
            fill: ${Colors.primary.black.standard};
          }
        }
      }
      :hover {
        transition-timing-function: ease-in;

        .slide-icon {
          transform: translateX(30%);
        }
      }
    }
  }

  .video-controls {
    align-items: center;
    color: ${Colors.primary.black.standard};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: auto;
    width: 166px;
    left: calc(50% - 3rem);
    bottom: 60px;
    position: absolute;

    .video-indicator {
      border-bottom: 1px solid ${Colors.primary.black.standard};
      font-family: 'source-sans-pro', sans-serif;
      font-size: 18px;
    }

    button {
      border-radius: 50%;
      display: block;
      height: 56px;
      vertical-align: middle;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    button svg * {
      fill: ${Colors.primary.black.standard};
    }
  }

  .control-dots {
    display: none;
  }

  .carousel.carousel-slider button.control-arrow {
    display: none;
  }

  @media screen and (min-width: ${sm_mobile_breakpoint}px) {
    .video-controls {
      left: calc(50% - 24px);

      &.video-layout-left {
        right: 9%;
      }
    }
  }

  @media screen and (min-width: ${lg_mobile_breakpoint}px) {
    .video-controls {
      left: calc(50% - 1px);

      &.video-layout-left {
        right: 9%;
      }
    }
  }

  @media screen and (min-width: ${tablet_portrait_breakpoint}px) {
    .video-controls {
      left: calc(73% - 4px);

      &.video-layout-left {
        right: 9%;
      }
    }
  }

  @media screen and (min-width: ${desktop_breakpoint_extended}px) {
    .video-container {
      padding: 2rem 0;
      width: 80%;

      h3 {
        font-size: 32px;
        line-height: 38px;
      }
    }

    .slide-card {
      flex-direction: row;
      padding: 0;

      .slide-image {
        margin-bottom: 0;
      }

      .slide-detail {
        max-width: 310px;
        padding: 3rem 2rem 5rem;

        h3 {
          font-size: 25px;
          line-height: 30px;
        }
      }

      &.video-layout-left {
        .slide-image {
          margin-bottom: 0;
          margin-right: 2rem;
        }
      }

      &.video-layout-right {
        .slide-detail {
          margin-bottom: 0;
          margin-right: 2rem;
        }
      }
    }

    .video-controls {
      left: 14%;
      bottom: 48px;
    }
  }

  @media screen and (min-width: ${sm_laptop_breakpoint}px) {
    .video-container {
      width: 80%;
      max-width: 1400px;
    }

    .slide-card {
      .slide-detail {
        padding: 3rem 2rem;
      }
    }

    .video-controls {
      top: initial;
      left: 11%;
      bottom: 60px;

      &.video-layout-left {
        left: initial;
        right: 4%;
      }
    }
  }

  @media screen and (min-width: ${sm_desktop_breakpoint}px) {
    .slide-card {
      .slide-detail {
        max-width: 543px;
      }
    }

    .video-controls {
      left: 20%;

      &.video-layout-left {
        right: 9%;
      }
    }
  }
`;
