import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Colors from 'pubweb-smokey/dist/colors';
import Button from '@components/Shared/Buttons/Button';
import WistiaVideoEmbed from '@components/Shared/WistiaVideoEmbed/WistiaVideoEmbed';
import useWindowResize from 'pubweb-smokey/dist/hooks/useWindowResize';
import { desktop_breakpoint_extended } from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
import StArrowLeftSvg from 'pubweb-smokey/dist/images/svg/iconography-16x16/st-arrow-left.svg';
import StArrowRightSvg from 'pubweb-smokey/dist/images/svg/iconography-16x16/st-arrow-right.svg';
import { VideoSectionStyles } from './VideoSection.styled';
import Slideshow from './Slideshow/Slideshow';
import Markdown from '@components/Shared/Markdown/Markdown';
import { extractColors, formatHttpsUrl } from '@utils/utils';
import { slugify } from '@utils/studioUtils';
import {
  getContentTextAlignment,
  getContentFlexAlignment,
} from '@utils/contentful';

const SlideLinks = ({ imageLinks, alignText }) => {
  return (
    <ul
      className="slide-links"
      style={{ justifyContent: getContentFlexAlignment(alignText) }}
    >
      {imageLinks?.length > 0 &&
        imageLinks.map((l, i) => {
          return (
            <li
              key={`slide-${slugify(l.transitionTitle)}-${i + 1}`}
              className="slide-link"
            >
              <Link
                href={l.link}
                style={{
                  color: extractColors(l?.buttonAndtextColors)?.[1],
                }}
              >
                <div className="slide-link-text">
                  {l.linkText}{' '}
                  <div className="slide-icon">
                    <StArrowRightSvg />
                  </div>
                </div>
              </Link>
            </li>
          );
        })}
    </ul>
  );
};

SlideLinks.propTypes = {
  alignText: PropTypes.string,
  imageLinks: PropTypes.arrayOf(
    PropTypes.shape({
      linkText: PropTypes.string,
      link: PropTypes.string,
      buttonAndtextColors: PropTypes.string,
    })
  ),
};

const getImages = ({
  images,
  sectionLinks,
  layout,
  alignText,
  headerTextColor,
  introTextColor,
  backgroundColor,
  isMobile,
}) => {
  // I hate doing this, but the carousel wants to basically eat my tabbing and I have to kill it so we can manage the tabbing
  // ourselves
  if (typeof document !== 'undefined') {
    setTimeout(() => {
      if (document.querySelectorAll('.carousel-root').length > 0) {
        document.querySelectorAll('.carousel-root')[0].tabIndex = -1;
      }
    }, 1);
  }

  return images.map((image, index) => {
    return (
      <div
        key={`image-${index}`}
        className={`slide-card ${
          layout === 'Video Block Left'
            ? 'video-layout-left'
            : 'video-layout-right'
        }`}
        style={{
          backgroundColor: isMobile ? backgroundColor : null,
        }}
      >
        <div
          className="slide-detail"
          style={{
            backgroundColor: !isMobile ? backgroundColor : null,
            textAlign: getContentTextAlignment(alignText),
          }}
        >
          <h3 style={{ color: headerTextColor }}>
            {image?.embeddedContentHeader}
          </h3>
          <div style={{ color: introTextColor }}>
            <Markdown markdown={image?.embeddedContentLongIntro} />
          </div>
          {image?.links.length > 0 && (
            <SlideLinks imageLinks={image.links} alignText={alignText} />
          )}
          {sectionLinks?.length > 0 && (
            <SlideLinks imageLinks={sectionLinks} alignText={alignText} />
          )}
        </div>
        <div className="slide-image">
          {image?.embeddedContent && image?.videoSource === 'Wistia' && (
            <WistiaVideoEmbed wistiaID={image?.embeddedContent} />
          )}
          {image?.embeddedContent && image?.videoSource === 'Widen' && (
            <>
              <iframe
                src={`https://embed.widencdn.net/video/claytonhomes/${image?.embeddedContent}`}
                webkitallowfullscreen
                mozallowfullscreen
                allowFullScreen
              ></iframe>
            </>
          )}
          {image?.embeddedImage && (
            <Image
              alt={image?.embeddedImage?.description}
              src={
                formatHttpsUrl(image?.embeddedImage?.file.url) +
                '?q=85&w=808&fm=webp'
              }
              width={808}
              height={606}
            />
          )}
        </div>
      </div>
    );
  });
};

const VideoSection = ({
  alignText,
  headerTextColor,
  introTextColor,
  textBlockBackgroundColor,
  layout,
  videos,
  sectionHeader,
  sectionBackgroundColor,
  sectionLinks,
}) => {
  const router = useRouter();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isMobile, setIsMobile] = useState(true);

  useWindowResize(() => {
    if (window.innerWidth > desktop_breakpoint_extended) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, [desktop_breakpoint_extended]);

  const prevSlide = () => {
    if (currentSlide === 0) {
      setCurrentSlide(videos.length - 1);
    } else {
      setCurrentSlide(currentSlide - 1);
    }
  };

  const nextSlide = () => {
    if (currentSlide === videos.length - 1) {
      setCurrentSlide(0);
    } else {
      setCurrentSlide(currentSlide + 1);
    }
  };

  return (
    <VideoSectionStyles
      style={{
        backgroundColor: extractColors(sectionBackgroundColor),
      }}
    >
      <div className="video-container">
        <h3
          style={{
            color: extractColors(headerTextColor),
          }}
        >
          {sectionHeader}
        </h3>
        <div
          tabIndex="0"
          className="focus-grabber"
          onKeyDown={(e) => {
            if (e.code === 13) {
              router.push(videos[currentSlide].links.link);
            }
          }}
        >
          <Slideshow
            tabIndex="-1"
            transitionTime={200}
            showThumbs={false}
            selectedItem={currentSlide}
            imageArray={getImages({
              images: videos,
              sectionLinks,
              alignText,
              headerTextColor: extractColors(headerTextColor),
              introTextColor: extractColors(introTextColor),
              backgroundColor: extractColors(textBlockBackgroundColor),
              layout,
              isMobile,
            })}
            onChange={(index) => {
              setCurrentSlide(index);
            }}
          />
        </div>
        {videos?.length > 1 && (
          <div
            className={`video-controls ${
              layout === 'Video Block Left'
                ? 'video-layout-left'
                : 'video-layout-right'
            }`}
          >
            <span className="video-indicator">
              {currentSlide + 1} / {videos.length}
            </span>
            <Button
              className="gtm-adf-facts-carousel"
              buttonStyle="compact"
              type="button"
              onClick={prevSlide}
              primaryColor={Colors.primary.white}
              automationId="button-previous-slide"
              style={{
                backgroundColor: extractColors(
                  videos[0]?.links[0]?.buttonAndtextColors
                )?.[0],
              }}
            >
              <StArrowLeftSvg viewBox="0 0 16 16" />
            </Button>
            <Button
              className="gtm-adf-facts-carousel"
              buttonStyle="compact"
              type="button"
              onClick={nextSlide}
              primaryColor={Colors.primary.white}
              automationId="button-next-slide"
              style={{
                backgroundColor: extractColors(
                  videos[0]?.links[0]?.buttonAndtextColors
                )?.[0],
              }}
            >
              <StArrowRightSvg viewBox="0 0 16 16" />
            </Button>
          </div>
        )}
      </div>
    </VideoSectionStyles>
  );
};

export default VideoSection;

VideoSection.propTypes = {
  alignText: PropTypes.string,
  headerTextColor: PropTypes.string,
  introTextColor: PropTypes.string,
  textBlockBackgroundColor: PropTypes.string,
  layout: PropTypes.string,
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      embeddedContentHeader: PropTypes.string,
      embeddedContentLongIntro: PropTypes.string,
      embeddedContent: PropTypes.string,
      mediaType: PropTypes.string,
      videoSource: PropTypes.string,
      links: PropTypes.arrayOf(
        PropTypes.shape({
          linkText: PropTypes.string,
          link: PropTypes.string,
          buttonAndtextColors: PropTypes.string,
        })
      ),
    })
  ),
  sectionHeader: PropTypes.string,
  sectionBackgroundColor: PropTypes.string,
  sectionLinks: PropTypes.arrayOf(
    PropTypes.shape({
      linkText: PropTypes.string,
      link: PropTypes.string,
      buttonAndtextColors: PropTypes.string,
    })
  ),
};
