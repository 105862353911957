import styled from 'styled-components';
import {
  desktop_breakpoint_extended,
  mobile_width_percentage,
} from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

export const ImageGridStyles = styled.section`
  margin: auto;
  padding: 96px 0px;
  width: ${mobile_width_percentage}%;

  @media only screen and (min-width: 426px) {
    width: 68%;
  }
  @media only screen and (min-width: ${desktop_breakpoint_extended}px) {
    width: ${mobile_width_percentage}%;
    max-width: 1200px;
  }

  .card-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-gap: 24px;

    .card-1 {
      grid-area: 1 / 1;
    }
    .card-2 {
      grid-area: 2 / 1;
    }
    .card-3 {
      grid-area: 3 / 1;
    }
  }

  @media only screen and (min-width: ${desktop_breakpoint_extended}px) {
    .card-grid {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr;
      .card-1 {
        grid-area: 1 / 1;
      }
      .card-2 {
        grid-area: 1 / 2;
      }
      .card-3 {
        grid-area: 1 / 3;
      }
    }
  }
`;

export default ImageGridStyles;
