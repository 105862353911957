import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import ImageCard from './ImageCard';
import useWindowResize from 'pubweb-smokey/dist/hooks/useWindowResize';
import {
  tablet_breakpoint,
  tablet_container_maxwidth,
  desktop_breakpoint,
  desktop_breakpoint_extended,
  desktop_container_maxwidth_compact,
  desktop_container_maxwidth,
} from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
import ImageGridStyles from './ImageGrid.styled';
import { extractColors } from '@utils/utils';

const ImageGrid = ({ cards, cardFeatureId, isFirstImageGrid, bgColor }) => {
  const [layoutToggle, setLayoutToggle] = useState(0);
  const style = isFirstImageGrid ? 'card-standard' : 'card-impact';
  const imageGridReference = 'image-grid-' + cardFeatureId;

  //Listen for window resizing at these breakpoints.
  useWindowResize(() => {
    if (typeof window !== 'undefined') {
      setLayoutToggle(window.innerWidth);
    }
  }, [
    tablet_breakpoint,
    tablet_container_maxwidth,
    desktop_breakpoint,
    desktop_breakpoint_extended,
    desktop_container_maxwidth_compact,
    desktop_container_maxwidth,
  ]);

  //Extra long text is not expected, however, if the text is very long, make the cards the same height.
  useLayoutEffect(() => {
    if (typeof window !== 'undefined') {
      undoElementHeight(imageGridReference);
      setElementHeight(imageGridReference);
    }
  }, [layoutToggle]);

  const setElementHeight = (className) => {
    const cards = document.getElementsByClassName(className);
    let maxHeight = 0;

    //Find the maximum height for cards.
    Array.from(cards).forEach((card) => {
      const cardHeight = card.offsetHeight;
      if (cardHeight) {
        if (cardHeight > maxHeight) {
          maxHeight = cardHeight;
        }
      }
    });

    if (maxHeight > 0) {
      Array.from(cards).forEach((card) => {
        card.style.height = `${maxHeight}px`;
      });
    }
  };

  const undoElementHeight = (className) => {
    const cards = document.getElementsByClassName(className);
    Array.from(cards).forEach((card) => {
      card.style.height = '';
    });
  };

  return (
    <section style={{ backgroundColor: extractColors(bgColor) }}>
      <ImageGridStyles>
        <section className="card-grid">
          <div className="card-1">
            {cards[0] && (
              <ImageCard
                card={cards[0]}
                style={style}
                imageGridReference={imageGridReference}
              />
            )}
          </div>
          <div className="card-2">
            {cards[1] && (
              <ImageCard
                card={cards[1]}
                style={style}
                imageGridReference={imageGridReference}
              />
            )}
          </div>
          <div className="card-3">
            {cards[2] && (
              <ImageCard
                card={cards[2]}
                style={style}
                imageGridReference={imageGridReference}
              />
            )}
          </div>
        </section>
      </ImageGridStyles>
    </section>
  );
};

export default ImageGrid;

ImageGrid.propTypes = {
  cards: PropTypes.array,
  cardFeatureId: PropTypes.number,
  isFirstImageGrid: PropTypes.bool,
  bgColor: PropTypes.string,
};
