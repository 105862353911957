import styled from 'styled-components';
import {
  tablet_portrait_breakpoint,
  tablet_landscape_breakpoint,
  sm_mobile_breakpoint,
  lg_mobile_breakpoint,
} from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

export const ImageCardStyles = styled.section`
  background-image: url(${(props) =>
    props.$backgroundImage ? props.$backgroundImage : 'none'});

  background-repeat: no-repeat;
  background-size: 'contain';
  box-sizing: border-box;
  background-position: 50% 0%;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;

  .card-standard,
  .card-impact {
    min-height: 216px;
    @media only screen and (min-width: ${tablet_portrait_breakpoint}px) {
      min-height: 290px;
    }
    @media only screen and (min-width: ${tablet_landscape_breakpoint}px) {
      min-height: 240px;
    }
  }

  .card-standard {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    overflow: auto;
    .footer {
      grid-area: 3 / 1 / 3 / 1;
      padding-left: 24px;
      display: flex;
      align-items: center;
    }

    h5 {
      grid-area: 2 / 1 / 2 / 1;
      padding-left: 24px;
      display: flex;
      align-items: center;
      padding-left: 24px;
      padding-right: 12%;
    }

    @media only screen and (min-width: ${sm_mobile_breakpoint}px) {
      h5 {
        padding-right: 15%;
      }
    }
    @media only screen and (min-width: ${lg_mobile_breakpoint}px) {
      h5 {
        padding-right: 42%;
      }
    }
    @media only screen and (min-width: 890px) {
      h5 {
        padding-right: 26%;
      }
    }
    @media only screen and (min-width: ${tablet_landscape_breakpoint}px) {
      h5 {
        padding-right: 20%;
      }
    }
  }

  .card-impact {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    overflow: auto;
    .footer {
      grid-area: 2 / 1;
      padding-left: 24px;
      padding-bottom: 18px;
      display: flex;
      align-items: flex-end;
    }

    h5 {
      grid-area: 1 / 1;
      padding-top: 48px;
      padding-left: 24px;
      padding-right: 12%;
    }

    @media only screen and (min-width: ${sm_mobile_breakpoint}px) {
      h5 {
        padding-right: 20%;
      }
    }
    @media only screen and (min-width: ${lg_mobile_breakpoint}px) {
      h5 {
        padding-right: 42%;
      }
    }
    @media only screen and (min-width: 890px) {
      h5 {
        padding-right: 24%;
      }
    }
    @media only screen and (min-width: ${tablet_landscape_breakpoint}px) {
      h5 {
        padding-right: 16%;
      }
    }
  }

  //Fonts
  .card-standard,
  .card-impact {
    h5 {
      text-shadow: rgb(37 37 37) 0 0 3px;
      color: white;
      font-family: 'source-sans-pro', Helvetica, Arial, sans-serif;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.3px;
    }
  }

  a {
    text-decoration: none;
  }

  .markdown {
    p:first-child {
      font-family: 'source-sans-pro', Helvetica, Arial, sans-serif;
      font-size: 12px;
      font-weight: 600;
      line-height: 12px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      text-shadow: rgb(37 37 37) 0 0 3px;
      color: white;
      font-family: 'acumin-pro', sans-serif;
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
      letter-spacing: -0.35px;
      word-wrap: break-word;
    }
    p {
      text-shadow: rgb(37 37 37) 0 0 3px;
      color: white;
      font-family: 'acumin-pro', sans-serif;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.3px;
      text-transform: none;
      word-wrap: break-word;
    }
  }
`;

export default ImageCardStyles;
