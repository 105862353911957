/* eslint-disable react/prop-types */
import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { getSiteUrl } from '@utils/config';
import HeroSection from '@components/Shared/HeroSection/HeroSection';
import SnippetSection from '@components/Shared/SnippetSection/SnippetSection';
import TextSectionRouter from '@components/Shared/TextSectionRouter/TextSectionRouter';
import ImageGrid from '@components/Shared/ImageGrid/ImageGrid';
import PageTransitionContainer from '@components/Shared/PageTransition/PageTransitionContainer';
import VideoSection from '@components/Shared/VideoSection/VideoSection';
import WebForm from '@components/Shared/WebForm/WebForm';
import PreFooterSection from '@components/Shared/PreFooterSection/PreFooterSection';
import DynamicDisclaimer from '@components/Shared/Disclaimer/DynamicDisclaimer';
import { extractColors } from '@utils/utils';
import styles from './WebpageTemplate.module.css';
import ImageSlider from '@components/Shared/ImageSlider/ImageSlider';
import FeaturedHomes from '@components/Shared/FeaturedHomes/FeaturedHomes';
import CTASection from '@components/Shared/CTASection/CTASection';
import ThreeImageGridContainer from '@components/Shared/ThreeImageGrid/ThreeImageGridContainer';
import dynamic from 'next/dynamic';
import { groupPreFooterActions } from '@utils/webpageTemplate';

const EmbeddedSection = dynamic(
  () => import('@components/Shared/EmbeddedSection/EmbeddedSection'),
  {
    ssr: false,
  }
);

/**
 * Webpage Template used to dynamically display Contentful content on a page with shared components
 *
 * @param {{
 *  pageInfo: {
 *    contentType: string
 *    contentfulName: string
 *    disclaimer: string
 *    disclaimerBackgroundAndTextColors: string
 *    heroSection: {}
 *    mainContent: {
 *      contentType: string
 *      gridLayout?: string
 *      images?: []
 *      gridBackgroundColor?: string
 *    }[]
 *    metaDescription: string
 *    ogImage: { url: string }
 *    pageURL: string
 *    preFooterActions: { contentType: string }[]
 *    siteHeader: string
 *    titleTag: string
 *  }
 * }}
 */
export function WebpageTemplate({ pageInfo }) {
  const router = useRouter();

  return (
    <div className={styles['webpage-template']}>
      <Head>
        <title>{pageInfo?.titleTag}</title>
        <meta name="description" content={pageInfo?.metaDescription} />
        <link rel="canonical" href={`${getSiteUrl(router?.pathname)}`} />
        <meta
          property="og:image"
          content={
            pageInfo?.ogImage?.url ||
            getSiteUrl('/public-assets/images/clayton-logo.jpg')
          }
        />
      </Head>

      {pageInfo?.heroSection && (
        <HeroSection heroSection={pageInfo?.heroSection} />
      )}

      {pageInfo?.mainContent?.map((contentSection, i, mainContent) => {
        switch (contentSection.contentType) {
          case 'textSection':
            return <TextSectionRouter {...contentSection} />;
          case 'snippetSection':
            return <SnippetSection section={contentSection} />;
          case 'pageTransition':
            return <PageTransitionContainer {...contentSection} />;
          case 'imageGrid':
            if (contentSection?.gridLayout == 'Three Images') {
              return (
                <ImageGrid
                  cards={contentSection?.images}
                  bgColor={contentSection?.gridBackgroundColor}
                />
              );
            } else if (
              contentSection?.gridLayout === 'Image Slider with Static Text'
            ) {
              return <ImageSlider section={contentSection} />;
            } else if (
              contentSection?.gridLayout === 'Three Images: Large-Small' ||
              contentSection?.gridLayout === 'Three Images: Small-Large'
            ) {
              return (
                <ThreeImageGridContainer mainContent={mainContent} index={i} />
              );
            } else {
              return <></>;
            }
          case 'videoSection':
            return <VideoSection {...contentSection} />;
          case 'featuredHomesSection':
            return <FeaturedHomes section={contentSection} />;
          case 'embeddedContent':
            return <EmbeddedSection section={contentSection} />;
          case 'cta':
            return <CTASection section={contentSection} />;
          default:
            return null;
        }
      })}

      {groupPreFooterActions(pageInfo?.preFooterActions)?.map(
        (contentSection) => {
          switch (contentSection.contentType) {
            case 'webForm':
              return <WebForm webFormData={contentSection} />;
            case 'cta': {
              const actions = contentSection?.insetCtas
                ? contentSection.insetCtas
                : [contentSection];
              return (
                <div className={styles.preFooterCta}>
                  <PreFooterSection preFooterActions={actions} />
                </div>
              );
            }
            default:
              return null;
          }
        }
      )}

      {pageInfo?.disclaimer && (
        <DynamicDisclaimer
          disclaimerContent={pageInfo?.disclaimer}
          backgroundColor={
            extractColors(pageInfo?.disclaimerBackgroundAndTextColors)?.[0]
          }
          textColor={
            extractColors(pageInfo?.disclaimerBackgroundAndTextColors)?.[1]
          }
        />
      )}
    </div>
  );
}
