import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import Button from '@components/Shared/Buttons/Button';
import ImageSliderStyles from './ImageSlider.styled';
import ImageCarousel from '../ImageCarousel/ImageCarousel';
import Markdown from '../Markdown/Markdown';
import { slugify } from '@utils/studioUtils';

const ImageSlider = ({ section }) => {
  return (
    <ImageSliderStyles id={slugify(section?.sectionHeader)}>
      <ImageCarousel images={section?.images} />
      <div className="img-slider-container">
        <div className="img-slider-text">
          <h2>{section?.sectionHeader}</h2>
          <div className="img-slider-intro">
            <Markdown markdown={section?.sectionIntro} />
          </div>
          {section?.gridLinks?.[0] && (
            <Button
              // className="gtm-crsmd-crossmod-building-process"
              navigateUrl={section?.gridLinks?.[0].link}
              linkComponent={Link}
            >
              {section?.gridLinks?.[0].linkText}
            </Button>
          )}
        </div>
      </div>
    </ImageSliderStyles>
  );
};

export default ImageSlider;

ImageSlider.propTypes = {
  section: PropTypes.object,
};
