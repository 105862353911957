import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import {
  tablet_portrait_breakpoint,
  desktop_breakpoint_extended,
} from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
import ChevronRight from 'pubweb-smokey/dist/images/svg/iconography-16x16/chevron-right.svg';
import ImageCarouselStyles from './ImageCarousel.styled';
import Image from 'next/image';
import { formatHttpsUrl } from '@utils/utils';

import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';

import 'pure-react-carousel/dist/react-carousel.es.css';

const ImageCarousel = ({ images }) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${tablet_portrait_breakpoint}px)`,
  });
  const isBelowDesktop = useMediaQuery({
    query: `(max-width: ${desktop_breakpoint_extended}px)`,
  });

  const slideHeight = () => {
    if (isMobile) {
      return 100;
    } else if (isBelowDesktop) {
      return 65;
    } else {
      return 100;
    }
  };

  const getSlides = (images) => {
    let data = [];
    images?.forEach((img) => {
      if (img?.imageAsset?.file?.url) {
        data.push(img.imageAsset.file.url);
      } else if (img?.imageUrl) {
        data.push(img.imageUrl);
      }
    });
    return data;
  };

  const featureSlides = getSlides(images);

  const CarouselOptions = {
    naturalSlideWidth: 100,
    naturalSlideHeight: slideHeight(),
    totalSlides: featureSlides?.length || 0,
  };

  return (
    <ImageCarouselStyles>
      <CarouselProvider {...CarouselOptions}>
        <Slider>
          {featureSlides?.map((slide, index) => (
            <Slide key={index}>
              <Image
                src={formatHttpsUrl(`${slide}?w=1200`)}
                alt=""
                width={864}
                height={864}
              />
            </Slide>
          ))}
        </Slider>
        <ButtonBack className="direction-button direction-left">
          <div className="direction-svg">
            <ChevronRight />
          </div>
        </ButtonBack>
        <ButtonNext className="direction-button direction-right">
          <div className="direction-svg">
            <ChevronRight />
          </div>
        </ButtonNext>
      </CarouselProvider>
    </ImageCarouselStyles>
  );
};

export default ImageCarousel;

ImageCarousel.propTypes = {
  images: PropTypes.array,
};
