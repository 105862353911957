import styled from 'styled-components';

const ImageCarouselStyles = styled.div`
  .carousel {
    position: relative;
  }

  button {
    background-color: transparent;
    border: none;
    line-height: 0;
  }

  .direction-svg {
    padding: 8px;
    border-radius: 16px;
    background-color: white;
  }

  .direction-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    > div {
      padding: 4px;
    }

    svg {
      width: 24px;
      height: 24px;
      transform: translateX(1px);
    }

    &:hover svg {
      transform: translateX(3px);
    }
  }

  .direction-button:disabled {
    display: none;
  }

  .direction-left {
    left: 20px;
    transform: scaleX(-1);

    path {
      transform: translate(5px, 3px);
    }
  }

  .direction-right {
    right: 20px;
    path {
      transform: translate(5px, 3px);
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export default ImageCarouselStyles;
