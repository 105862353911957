import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

const wistiaResponsivePaddingStyles = {
  padding: '56.25% 0 0 0',
  position: 'relative',
};
const wistiaResponsiveWrapperStyles = {
  height: '100%',
  left: '0',
  position: 'absolute',
  top: '0',
  width: '100%',
};
const wistiaEmbedStyles = {
  height: '100%',
  position: 'relative',
  width: '100%',
};
const wistiaSwatchStyles = {
  height: '100%',
  left: '0',
  opacity: '0',
  overflow: 'hidden',
  position: 'absolute',
  top: '0',
  transition: 'opacity 200ms',
  width: '100%',
};
const wistiaImgStyles = {
  filter: 'blur(5px)',
  height: '100%',
  objectFit: 'contain',
  width: '100%',
};

const WistiaVideoEmbed = ({ wistiaID, altText }) => {
  useEffect(() => {
    if (typeof document !== 'undefined') {
      const script1 = document.createElement('script');
      const script2 = document.createElement('script');

      script1.src =
        `https://fast.wistia.com/embed/medias/` + wistiaID + `.jsonp`;
      script1.async = true;

      script2.src = 'https://fast.wistia.com/assets/external/E-v1.js';
      script2.async = true;

      document.body.appendChild(script1);
      document.body.appendChild(script2);
    }
  });

  return (
    <div
      className="wistia_responsive_padding"
      style={wistiaResponsivePaddingStyles}
    >
      <div
        className="wistia_responsive_wrapper"
        style={wistiaResponsiveWrapperStyles}
      >
        <div
          className={
            'wistia_embed wistia_async_' + wistiaID + ' videoFoam=true'
          }
          style={wistiaEmbedStyles}
        >
          <div className="wistia_swatch" style={wistiaSwatchStyles}>
            <img
              alt={altText ? altText : 'Video'}
              src={
                'https://fast.wistia.com/embed/medias/' + wistiaID + '/swatch'
              }
              style={wistiaImgStyles}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

WistiaVideoEmbed.propTypes = {
  altText: PropTypes.string,
  wistiaID: PropTypes.string,
};

export default WistiaVideoEmbed;
