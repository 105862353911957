import React from 'react';
import PropTypes from 'prop-types';
import TextSection from '../TextSection/TextSection';
import TextSectionInset from '../TextSectionInset/TextSectionInset';

const TextSectionRouter = (props) => {
  return props.imageLocation === 'Inset Background' ? (
    <TextSectionInset {...props} />
  ) : (
    <TextSection section={props} />
  );
};

export default TextSectionRouter;

TextSectionRouter.propTypes = {
  imageLocation: PropTypes.string,
};
