import styled from 'styled-components';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
import Colors from 'pubweb-smokey/dist/colors';

import ChevronRight from 'pubweb-smokey/dist/images/svg/iconography-16x16/chevron-right.svg';
import ChevronLeft from 'pubweb-smokey/dist/images/svg/iconography-16x16/chevron-lft.svg';

export const SlideshowStyles = styled.div`
  position: relative;

  .slider-wrapper {
    .slide {
      background-color: transparent;

      div {
        img {
          pointer-events: all;
          max-height: ${(props) =>
            props.$viewportWidth < variables.desktop_container_maxwidth
              ? props.$viewportWidth / 1.75 + 'px'
              : '730px'};
          min-height: 275px; //spacing around images, while loading images
          min-width: 275px; //spacing around images, while loading images
          object-fit: contain;
        }
      }
    }
  }
  .carousel {
    &.carousel-slider {
      overflow: visible;

      .control-arrow,
      .control-arrow:hover {
        width: 55px;
        height: 55px;
        background-color: #fff;
        border-radius: 50%;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
        opacity: 1;
        top: 40%;
        display: block;

        &:before {
          border: none;
          background-size: cover;
          content: ' ';
          position: relative;
          display: inline-block;
          width: 20px;
          height: 20px;
          top: 3px;
        }
      }
      .control-prev {
        left: -2.5%;

        &:before {
          background-image: url(${ChevronLeft});
          left: 3px;
        }
      }
      .control-next {
        right: -2.5%;

        &:before {
          background-image: url(${ChevronRight});
          left: 8px;
        }
      }
    }

    .thumbs-wrapper {
      margin: 10px 0;

      .control-arrow {
        background-color: ${Colors.accent.grey1.standard};
        width: 25px;
        height: 25px;
        margin-top: -15px;
        opacity: 0.8;
      }

      .thumb {
        border: none;
        max-height: 80px;
        padding: 0;
        cursor: pointer;

        &.selected {
          border: solid 2px black;
        }
      }
    }
  }

  @media only screen and (min-width: ${variables.desktop_breakpoint}px) {
    position: relative;

    .carousel {
      .slide {
        img {
          width: 100%;
        }
      }
      &.carousel-slider {
        overflow: visible;

        .control-arrow,
        .control-arrow:hover {
          /* !important is used here because react-responsive-carousel loves control */
          display: ${(props) =>
            props.$showArrows ? 'display !important' : 'none !important'};
          width: 55px;
          height: 55px;
          background-color: #fff;
          border-radius: 50%;
          box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
          opacity: 1;
          top: 47%;
          display: block;

          &:before {
            border: none;
            background-size: cover;
            content: ' ';
            position: relative;
            display: inline-block;
            width: 20px;
            height: 20px;
            top: 3px;
          }
        }
        .control-prev {
          left: -2.5%;

          &:before {
            background-image: url(${ChevronLeft});
            left: 3px;
          }
        }
        .control-next {
          right: -2.5%;

          &:before {
            background-image: url(${ChevronRight});
            left: 8px;
          }
        }
      }
    }
  }
`;
