import styled from 'styled-components';
import { desktop_breakpoint_extended } from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

const ImageSliderStyles = styled.div`
  /* padding-top: 64px; */

  .img-slider-intro {
    margin: 40px 0 40px 32px;
  }

  .img-slider-container {
    display: flex;
    align-items: center;
  }
  .img-slider-text {
    padding: 0 16px;
    margin: 48px 0;
    width: 100%;
  }

  @media screen and (min-width: ${desktop_breakpoint_extended}px) {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    .img-slider-text {
      padding-left: 96px;
      margin: 0;
    }
  }
`;

export default ImageSliderStyles;
