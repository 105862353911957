import styled from 'styled-components';

export const CTASectionStyles = styled.div`
  background-color: ${(props) => (props.color ? props.color : '#fff')};

  .cta {
    max-width: 100%;
  }

  .cta-inside {
    .cta-heading,
    .cta-text,
    .label {
      color: #4e5255;
      margin: 15px auto;
    }

    .cta-heading {
      margin-top: 0;
      font-size: 16px;
      line-height: 1.69;
      letter-spacing: normal;
      font-weight: normal;
      font-family: 'source-sans-pro', Helvetica, Arial, sans-serif;
    }
  }
`;

export default CTASectionStyles;
