/* eslint-disable react/prop-types */
import React from 'react';
import styles from './ThreeImageGrid.module.css';
import Image from 'next/image';

/**
 * Large-Small/Small-Large Three Image Grid component.
 *
 * layoutType
 * - large-to-small: 1 large image on the left with 2 smaller images on the right
 * - small-to-large: 2 smaller images on the left with 1 large image on the right
 *
 * @param {{
 *   layoutType: 'large-to-small' | 'small-to-large',
 *   images: { src: string, altText: string }[],
 *   hasPreviousImageGrid: boolean,
 *   hasNextImageGrid: boolean,
 *   backgroundColor: string
 * }}
 */
export default function ThreeImageGrid({
  layoutType,
  images,
  backgroundColor,
  hasPreviousImageGrid,
  hasNextImageGrid,
}) {
  let largeImage;
  let smallImage1;
  let smallImage2;

  if (layoutType === 'large-to-small') {
    largeImage = { ...images[0] };
    smallImage1 = { ...images[1] };
    smallImage2 = { ...images[2] };
  } else if (layoutType === 'small-to-large') {
    smallImage1 = { ...images[0] };
    smallImage2 = { ...images[1] };
    largeImage = { ...images[2] };
  }

  const firstInListStyles = hasPreviousImageGrid ? '' : styles['first-in-list'];
  const lastInListStyles = hasNextImageGrid ? '' : styles['last-in-list'];

  return (
    <section
      className={`${styles['grid-wrapper']} ${firstInListStyles} ${lastInListStyles}`}
      style={{ backgroundColor }}
    >
      <ul className={`${styles['grid']} ${styles[layoutType]}`}>
        <li className={styles.large}>
          <Image
            data-testid="large-image"
            className={styles.image}
            src={`${largeImage.src}?q=75&w=750&fm=webp`}
            alt={largeImage.altText}
            fill
          />
        </li>
        <li className={styles.small1}>
          <Image
            data-testid="small-image-1"
            className={styles.image}
            src={`${smallImage1.src}?q=75&w=325&fm=webp`}
            alt={smallImage1.altText}
            fill
          />
        </li>
        <li className={styles.small2}>
          <Image
            data-testid="small-image-2"
            className={styles.image}
            src={`${smallImage2.src}?q=75&w=325&fm=webp`}
            alt={smallImage2.altText}
            fill
          />
        </li>
      </ul>
    </section>
  );
}
