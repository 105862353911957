import styled from 'styled-components';
import {
  lg_mobile_breakpoint,
  tablet_portrait_breakpoint,
  desktop_breakpoint_extended,
  sm_laptop_breakpoint,
  desktop_container_maxwidth,
} from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

export const TextSectionInsetStyles = styled.section`
  font-family: 'source-sans-pro', Helvetica, Arial, sans-serif;
  height: 612px;
  margin: 1rem 0 6rem 0;

  .full-width-card {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    padding: 48px 0px;
  }

  .card-overlay {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0 15px 0 20px;
  }

  .card-desc {
    padding-bottom: 15px;

    h3 {
      font-size: 26px;
      font-style: normal;
      font-weight: 700;
      line-height: 31px;
      letter-spacing: -0.35px;
      margin-bottom: 1rem;
    }

    h5 {
      font-weight: 600;
      font-size: 12px;
      line-height: 12px;
      margin-bottom: 1rem;
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }

    .markdown {
      h1 {
        font-size: 22px;
        line-height: 30px;
        font-weight: 700;
      }
      h2 {
        font-size: 20px;
        line-height: 30px;
        font-weight: 700;
      }
      h3 {
        font-size: 18px;
        line-height: 29px;
        font-weight: 400;
      }
      p {
        font-size: 16px;
        line-height: 27px;
        font-weight: 400;
      }
      p,
      p * {
        font-family: 'source-sans-pro', Helvetica, Arial, sans-serif;
      }
    }

    h1,
    h2,
    h3,
    h5,
    p {
      color: inherit;
    }
  }

  .card-links {
    list-style: none;
    display: block;
  }

  .card-link a {
    color: inherit;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: left;
    font-size: 18px;
    font-family: 'acumin-pro', sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: -0.3px;
    text-decoration: none;
    margin-bottom: 0.5em;

    .card-icon {
      display: inline-block;
      height: 14px;
      position: relative;
      width: 16px;
      transition: 0.5s;

      svg {
        pointer-events: none;
        margin-left: 5px;
        margin-top: 3px;
        position: absolute;
        top: calc(50% - 9px);

        * {
          fill: inherit;
        }
      }
    }
    :hover {
      transition-timing-function: ease-in;

      .card-icon {
        transform: translateX(30%);
      }
    }
  }

  .card-stats {
    h2 {
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px;
      letter-spacing: -0.35px;
      margin-bottom: 0.5rem;
    }

    p {
      font-family: 'acumin-pro', sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: normal;
      line-height: 24px;
      letter-spacing: -0.3px;
    }

    .markdown {
      h1 {
        font-size: 40px;
        line-height: 48px;
        font-weight: 700;
      }
      h2 {
        font-size: 32px;
        line-height: 38px;
        font-weight: 700;
      }
      h3 {
        font-size: 26px;
        line-height: 31px;
        font-weight: 700;
      }
      p {
        font-size: 20px;
        line-height: 24px;
        font-weight: 400;
      }
      p,
      p * {
        font-family: 'source-sans-pro', Helvetica, Arial, sans-serif;
      }
    }

    h1,
    h2,
    h3,
    p {
      color: inherit;
    }
  }

  @media screen and (min-width: ${lg_mobile_breakpoint}px) {
    p {
      line-height: 29px;
    }

    .card-overlay {
      padding: 0 35px;
    }
  }

  @media screen and (min-width: ${tablet_portrait_breakpoint}px) {
    .full-width-card {
      padding: 64px 0px;
    }

    .card-desc {
      padding-bottom: 29px;
    }

    .card-overlay {
      align-items: center;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 3rem;
      padding: 0 48px;
    }
  }

  @media screen and (min-width: ${desktop_breakpoint_extended}px) {
    .full-width-card {
      width: 80%;
      margin: auto;
      padding: 80px 0px;
      max-width: ${desktop_container_maxwidth}px;
    }

    .card-desc {
      h3 {
        font-size: 32px;
        line-height: 38px;
      }

      .markdown {
        h1 {
          font-size: 25px;
          line-height: 30px;
          font-weight: 700;
        }
        h2 {
          font-size: 20px;
          line-height: 30px;
          font-weight: 700;
        }
        h3 {
          font-size: 20px;
          line-height: 30px;
          font-weight: 400;
        }
        p {
          font-size: 18px;
          line-height: 29px;
          font-weight: 400;
        }
        p,
        p * {
          font-family: 'source-sans-pro', Helvetica, Arial, sans-serif;
        }
      }
    }

    .card-stats {
      h2 {
        font-size: 60px;
        line-height: 68px;
        letter-spacing: -0.52px;
      }

      .markdown {
        h1 {
          font-size: 60px;
          line-height: 68px;
          font-weight: 700;
        }
        h2 {
          font-size: 40px;
          line-height: 48px;
          font-weight: 700;
        }
        h3 {
          font-size: 32px;
          line-height: 38px;
          font-weight: 700;
        }
        p {
          font-size: 20px;
          line-height: 24px;
          font-weight: 400;
        }
        p,
        p * {
          font-family: 'source-sans-pro', Helvetica, Arial, sans-serif;
        }
      }
    }
  }

  @media screen and (min-width: ${sm_laptop_breakpoint}px) {
    .full-width-card {
      padding: 120px 0px;
    }

    .card-overlay {
      padding: 0 64px;
    }
  }
`;

export default TextSectionInsetStyles;
